<template>
<div class="w">
  <div class="crumbs">
    <router-link to="/">首页</router-link>
    <span>/</span>
    行业体系
    <span>/</span>
    <span style="color: #07C160">专家支持</span>
  </div>
  <ul class="nav">
    <li v-for="item of typeList" :key="item.id" :class="{active: typeActive === item.id}" @click="changeActive(item.id)">
      {{item.name}}
    </li>
  </ul>
  <div class="expert-list">
    <div class="expert-item" v-for="item of expertList" :key="item.id">
      <Expert :item="item"></Expert>
    </div>
  </div>
  <div class="pagebox" v-if="total">
    <el-pagination
      layout="prev, pager, next"
      :page-size="8"
      :current-page="pageNo"
      @current-change="pageChange"
      :total="total">
    </el-pagination>
  </div>
</div>
</template>

<script>
import { defineComponent, shallowRef, ref, onMounted } from 'vue'
import { ElPagination } from 'element3'
import Expert from '../components/Expert'
import { yncyExpertList } from '../api'

export default defineComponent({
  name: 'ExpertList',
  components: {
    ElPagination,
    Expert
  },
  setup () {
    // 专家列表
    const pageNo = ref(1)
    const expertList = ref([])
    const total = ref(0)
    const getExpertList = (data) => {
      yncyExpertList({
        exportCategory: data.exportCategory,
        pageSize: 8,
        pageNo: pageNo.value
      }).then(res => {
        total.value = res.result.total
        expertList.value = []
        res.result.records.forEach(item => {
          expertList.value.push(item)
        })
      })
    }

    // 专家类型
    const typeList = shallowRef([
      // { id: 0, name: '松材线虫病防治' },
      // { id: 1, name: '药剂药械' },
      // { id: 1, name: '卫星遥感' },
      // { id: 2, name: '监测预警' },
      // { id: 2, name: '多光谱遥感' },
      { id: 3, name: '鼠害专家' },
      { id: 0, name: '害虫专家' },
      // { id: 3, name: '药剂药械' },
      { id: 4, name: '病害专家' },
      // { id: 4, name: '病害防治' },
      // { id: 5, name: '毒害草防治' }
      { id: 5, name: '毒害草专家' }
    ])
    const typeActive = ref(0)
    const changeActive = (type) => {
      typeActive.value = type
      pageNo.value = 1
      getExpertList({ exportCategory: type })
    }

    const pageChange = (page) => {
      pageNo.value = page
      getExpertList({ exportCategory: typeActive.value })
    }

    onMounted(() => {
      getExpertList({ exportCategory: 0 })
    })

    return {
      typeList,
      typeActive,
      changeActive,
      expertList,
      pageChange,
      pageNo,
      total
    }
  }
})
</script>

<style lang="scss" scoped>
.expert-list{
  display: flex;
  flex-wrap: wrap;
}
.expert-item{
  margin: 0 26px 53px 0;
  &:nth-child(4n){
    margin-right: 0;
  }
}
.nav{
  display: flex;
  padding-top: 60px;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 52px;

  li{
    flex: 1;
    text-align: center;
    font-size: 22px;
    line-height: 46px;
    cursor: pointer;

    &.active{
      color: #07C160;
      border-bottom: 1px solid #07C160;
    }
  }

}
</style>
